import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const Image = styled(LazyImage)`
  position: absolute !important;
  width: 100%;
  height: 192px;
  right: 0;
  top: 0;
  z-index: 0;

  ${media.up('5xl')} {
    width: 50%;
    height: 100%;
  }
`
