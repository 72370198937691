import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Grid } from 'components/Grid'

export const ContentWrapper = styled(Grid)`
  position: relative;
  background: transparent;
  grid-template-areas: '${'content '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 24px;
    grid-template-areas: '. ${'content '.repeat(6)} ${'. '.repeat(5)}';
  }
`

export const Content = styled.div(
  ({ color, theme }) => css`
    grid-area: content;
    background: ${selectTheme(`colors.${color || 'neutral.10'}`)({ theme })};
    padding: 32px 24px;
    z-index: 1;

    ${media.up('5xl')} {
      background: transparent;
      padding: 0;
    }
  `,
)
