import { object } from 'prop-types'

import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { ArrowRightIcon } from 'components/Icons'

import { ActionButton } from './styles'

const propTypes = {
  buttonCta: object,
}

const ButtonCta = ({ buttonCta }) => {
  const { triggerEvent } = useTriggerEvents()

  const isValidButtonCta = buttonCta?.label && buttonCta?.actionHref
  if (!isValidButtonCta) return null

  const handleClick = () => {
    triggerEvent({
      label: buttonCta.label,
      pageInteraction: buttonCta?.actionType !== 'link',
    })
  }

  const buttonCtaProps = {
    ...buttonCta,
    variant: 'featuredButton',
    size: 'small',
    onClick: handleClick,
    analyticEvent: false,
  }

  return (
    <ActionButton {...buttonCtaProps}>
      <ArrowRightIcon />
    </ActionButton>
  )
}

ButtonCta.propTypes = propTypes

export default ButtonCta
