import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

export const Section = styled.section(
  ({ color, theme }) => css`
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;

    ${media.up('5xl')} {
      background: ${selectTheme(`colors.${color || 'neutral.10'}`)({ theme })};
    }
  `,
)

export const Container = styled(CreditasContainer)`
  max-width: 1248px;
  padding: 167px 0 64px 0;

  ${media.up('5xl')} {
    padding: 64px 0;
  }
`
