import { styled } from '@creditas-ui/system'
import { getTypography } from '@creditas-ui/utilities'

import { ButtonCta } from 'components/ButtonCta'

export const ActionButton = styled(ButtonCta)`
  margin-top: 40px;

  max-width: 163px;
  text-decoration: none;
  ${getTypography('bodySmMedium')}
`
