import { object, string } from 'prop-types'

import { SectionLogo } from './styles'

const propTypes = {
  'data-testid': string,
  sectionLogoImage: object,
}

const defaultProps = {
  'data-testid': 'section-exponencial-logo',
}

const Logo = ({ 'data-testid': dataTestid, sectionLogoImage }) => {
  if (!sectionLogoImage) return null
  return (
    <SectionLogo {...sectionLogoImage} data-testid={`${dataTestid}-logo`} />
  )
}

Logo.propTypes = propTypes
Logo.defaultProps = defaultProps

export default Logo
