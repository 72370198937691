import { object, string } from 'prop-types'

import { Image } from './styles'

const propTypes = {
  backgroundImageDesktop: object,
  backgroundImageMobile: object,
  'data-testid': string,
}

const defaultProps = {
  'data-testid': 'section-exponencial-background',
}

const BackgroundImage = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  'data-testid': dataTestid,
}) => {
  if (!backgroundImageDesktop || !backgroundImageMobile) return null

  const backgroundImage = {
    artDirection: [
      {
        ...backgroundImageDesktop.fluid,
        description: backgroundImageDesktop.description,
        media: '(min-width: 960px)',
        sizes: '(max-width: 1920px)',
      },
      {
        ...backgroundImageMobile.fluid,
        description: backgroundImageDesktop.description,
        sizes: '(max-width: 1024px)',
      },
    ],
  }

  return <Image {...backgroundImage} data-testid={`${dataTestid}-background`} />
}

BackgroundImage.propTypes = propTypes
BackgroundImage.defaultProps = defaultProps

export default BackgroundImage
