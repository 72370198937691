import { oneOf } from 'prop-types'

import Template01 from './Template01'
import Template02 from './Template02'

const propTypes = {
  variant: oneOf(['variant01', 'variant02']),
}

const defaultProps = {
  variant: 'variant01',
}

const ELEMENT_VARIANTS = {
  variant01: Template01,
  variant02: Template02,
}

const ExponencialBanner = ({ variant, ...others }) => {
  const currentVariant = variant ?? defaultProps.variant
  const Element = ELEMENT_VARIANTS[currentVariant]
  return <Element {...others} />
}

ExponencialBanner.propTypes = propTypes
ExponencialBanner.defaultProps = defaultProps

export default ExponencialBanner
