import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

export const SectionLogo = styled(LazyImage)`
  width: 142px;
  height: 32px;
  margin-bottom: 32px;

  ${media.up('5xl')} {
    margin-bottom: 48px;
  }
`
