import { object, oneOf, string } from 'prop-types'

import { Content, ContentWrapper } from '../base.styles'
import BackgroundImage from '../components/BackgroundImage'
import ButtonCta from '../components/ButtonCta'
import Logo from '../components/Logo'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  backgroundImageDesktop: object,
  backgroundImageMobile: object,
  buttonCta: object,
  'data-testid': string,
  description: string,
  headingTag: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  sectionLogoImage: object,
  title: string,
}

const defaultProps = {
  'data-testid': 'section-exponencial-banner',
  headingTag: 'h2',
}

const ExponencialBanner = ({
  backgroundImageDesktop,
  backgroundImageMobile,
  buttonCta,
  'data-testid': dataTestid,
  description,
  headingTag,
  sectionLogoImage,
  title,
  ...others
}) => {
  return (
    <Wrapper data-testid={dataTestid} {...others}>
      <BackgroundImage
        backgroundImageDesktop={backgroundImageDesktop}
        backgroundImageMobile={backgroundImageMobile}
        data-testid={dataTestid}
      />
      <ContentWrapper>
        <Content>
          <Logo
            sectionLogoImage={sectionLogoImage}
            data-testid={`${dataTestid}-logo`}
          />
          <Title as={headingTag || defaultProps.headingTag}>{title}</Title>
          <ButtonCta buttonCta={buttonCta} />
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

ExponencialBanner.propTypes = propTypes
ExponencialBanner.defaultProps = defaultProps

export default ExponencialBanner
